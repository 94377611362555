<template>
  <div>
    <v-menu offset-y>
      <!-- :disabled="order.expected_items_total && (order.orderStatus.name != 'price check')" -->
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          small
          :color="getColor(order.orderStatus.name)"
          class="white--text px-5"
          v-bind="attrs"
          v-on="on"
        >
          {{ order.orderStatus.name }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          dense
          v-for="status in nextStatuses"
          :key="status.id"
          @click="updateOrderStatus(order, status.id)"
        >
          <v-list-item-title v-text="status.name"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      orderStatuses: "orderStatuses/listStatuses",
      nextStatusesIds: "orderStatuses/nextStatusesIds",
    }),
    nextStatuses: function () {
      return this.orderStatuses.filter((item) => {
        return this.nextStatusesIds[this.order.orderStatus.name].includes(
          item.id
        );
      });
    },
  },

  props: {
    order: {
      types: Object,
    },
  },

  data: () => ({}),

  methods: {
    async updateOrderStatus(order, status_id) {
      try {
        await this.$store.dispatch("orders/updateStatus", {
          order_id: order.id,
          status_id,
        });
        this.$store.dispatch("alerts/success", this.$t("updated_successfully"));
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    getColor(status) {
      if (status === "price check") return "red";
      if (status === "pending" || status === "new order") return "orange";
      else if (status === "ready for shipping") return "light-green";
      else if (status === "shipped") return "green";
      else if (status === "delivered") return "blue";
      else return "grey";
    },
  },
};
</script>
